import React from "react";
import { gerFormat, gerFormatShort } from "./Utils";
import { FaUser } from "react-icons/fa";
import { Link } from "gatsby";
import Text from "../components/Text";
import HeroImages from "../components/HeroImages";
import { GatsbyImage } from "gatsby-plugin-image";
import German from "../images/german.png";
import { PostCounter } from "./PostCounter";

export const PostBody = ({
  originalLanguage,
  originalTitle,
  draft,
  contentful_id,
  Connect,
  commentCounter,
  originalText,
  richText,
  gallery,
  author,
  number,
  title,
  publishedAt,
  setGerman,
  german,
}) => {
  const Date = () => {
    return (
      <>
        <div className="sm:hidden">{gerFormatShort(publishedAt)}</div>
        <div className="hidden sm:contents">{gerFormat(publishedAt)}</div>
      </>
    );
  };

  const Author = () => {
    return (
      <Link to={`/beitraege`} state={{ author: author }}>
        <div className="flex items-center justify-end">
          <FaUser className="mr-0.5 opacity-70" size={11} />
          <div>{author}</div>
        </div>
      </Link>
    );
  };

  const LanguageSelector = () => {
    return (
      <>
        <div className="sm:hidden">
          {originalLanguage && (
            <>
              {german && (
                <button
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-1/2 bottom-1/2"
                  onClick={() => setGerman(!german)}
                >
                  <img src={German} alt="deutsch" height={25} width={25} />
                </button>
              )}
              {!german && (
                <button
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-1/2 bottom-1/2"
                  onClick={() => setGerman(!german)}
                >
                  <GatsbyImage
                    image={originalLanguage.flag.gatsbyImageData}
                    alt="bild"
                  />
                </button>
              )}
            </>
          )}
        </div>

        <div className="hidden sm:contents">
          {originalLanguage && (
            <div className="absolute left-1/2 transform -translate-x-1/2 translate-y-1/2 bottom-1/2">
              <button
                className={`mr-0.25 focus:outline-none ${
                  german ? "opacity-100" : "opacity-50"
                }`}
                onClick={() => setGerman(true)}
              >
                <img src={German} alt="deutsch" height={25} width={25} />
              </button>
              <button
                className={`ml-0.25 focus:outline-none ${
                  german ? "opacity-50" : "opacity-100"
                }`}
                onClick={() => setGerman(false)}
              >
                <GatsbyImage
                  image={originalLanguage.flag.gatsbyImageData}
                  alt="bild"
                />
              </button>
            </div>
          )}
        </div>
      </>
    );
  };

  const Title = () => {
    return (
      <h2 className="text-2xl font-bold pt-3">
        {german ? title : originalTitle}
      </h2>
    );
  };

  const RichText = () => {
    return (
      <div className="leading-relaxed">
        <Text text={german ? richText : originalText} />
      </div>
    );
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-lg md:shadow-sm transform">
      <HeroImages images={gallery} />
      <div className="p-3">
        <div className="flex justify-between text-gray-500 text-sm items-center relative">
          <Date />
          <LanguageSelector />
          <Author />
        </div>
        <Title />
        <RichText />
        <PostCounter
          commentCounter={commentCounter}
          number={number}
          Connect={Connect}
          contentful_id={contentful_id}
          draft={draft}
        />
      </div>
    </div>
  );
};
