import React from "react";
import { FaBookReader, FaComment } from "react-icons/fa";
import { Like } from "../components/Like";

export const PostCounter = ({
  number,
  commentCounter,
  contentful_id,
  Connect,
  draft,
}) => {
  return (
    !draft && (
      <div className="mt-8">
        <div className="text-sm relative w-full">
          <div className="right-0 bottom-0 absolute relative transform -translate-x-32">
            <FaBookReader className="opacity-30" />
            <div className="opacity-30 absolute right-0 transform translate-x-6 top-1/2 -translate-y-1/2">
              <div
                className={`w-5 text-center ${number == null && "invisible"}`}
              >
                {!number ? 0 : number}
              </div>
            </div>
          </div>
          <div className="right-0 bottom-0 absolute relative transform -translate-x-19">
            <FaComment className="opacity-30" />
            <div className="opacity-30 absolute right-0 transform translate-x-6 top-1/2 -translate-y-1/2">
              <div
                className={`w-5 text-center ${
                  commentCounter === "a" && "invisible"
                }`}
              >
                {commentCounter - 1}
              </div>
            </div>
          </div>
          <Like id={contentful_id} Connect={Connect} />
        </div>
      </div>
    )
  );
};
