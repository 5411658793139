import React from "react";
import { FaArrowCircleLeft, FaEdit } from "react-icons/fa";
import { Textarea } from "@chakra-ui/react";
import { MySpinner } from "../components/Utils";

export const Correction = ({
  handleInputChange,
  handleClick,
  setEditingMode,
  value,
  editingMode,
  correctionSpinner,
  text,
  correction,
  draft,
  user,
  listReadCorrection,
  checkRole,
  handleClickCorrectionButton,
}) => {
  return (
    <>
      <div className="h-6 flex justify-center relative">
        {checkRole(user, "admin") && (
          <button
            className={`absolute ${
              !JSON.stringify(listReadCorrection).includes(user.sub) &&
              text !== ""
                ? "opacity-40"
                : "opacity-0"
            } text-xs left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2`}
            onClick={handleClickCorrectionButton}
          >
            ungelesene Anmerkungen
          </button>
        )}
      </div>
      {(correction || draft) && (
        <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-lg md:shadow-sm mb-6 p-3 relative">
          {correctionSpinner && <MySpinner />}
          <div className="flex justify-between items-center mb-5">
            <div className="flex">
              <div className="font-bold">Anmerkungen/Korrekturen:</div>
              {(value == null || value === "") && (
                <div className="font-normal ml-1">Keine</div>
              )}
            </div>
            <button onClick={() => setEditingMode(!editingMode)}>
              {editingMode ? (
                <FaArrowCircleLeft size={25} className="text-red-600" />
              ) : (
                <FaEdit size={25} className="text-blue-700" />
              )}
            </button>
          </div>
          {editingMode ? (
            <>
              <Textarea
                rows={20}
                value={value}
                onChange={handleInputChange}
                placeholder="Nur zu!"
              />

              <button className="submitButton" onClick={handleClick}>
                Speichern
              </button>
            </>
          ) : (
            <div className="whitespace-pre-wrap">{text}</div>
          )}
        </div>
      )}
    </>
  );
};
