import { graphql, Link, useStaticQuery } from "gatsby";
import { FaNewspaper, FaYoutube } from "react-icons/fa";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { gerFormatShort } from "./Utils";

const PostSidebar = ({
  author,
  vPreview,
  tPreview,
  tags,
  related,
  category,
  serie,
  title,
}) => {
  const { allContentfulPost } = useStaticQuery(graphql`
    query {
      allContentfulPost(sort: { fields: publishedAt, order: DESC }) {
        nodes {
          title
          draft
          serie {
            title
          }
          slug
          publishedAt(formatString: "DD-MM-YYYY")
          gallery {
            title
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              quality: 100
            )
          }
        }
      }
    }
  `);

  const postsWithSerie = allContentfulPost.nodes
    .filter((item) => (item.serie ? item.serie.title : false))
    .reverse();

  const allPostsOfSerie = postsWithSerie
    .filter((item) => item.serie.title === serie?.title)
    .filter((item) => item.draft !== true)
    .map((item) => item.title);

  const allDatesOfSerie = postsWithSerie
    .filter((item) => item.serie.title === serie?.title)
    .map((item) => item.publishedAt);

  const allImagesOfSerie = postsWithSerie.filter(
    (item) => item.serie.title === serie?.title
  );

  const allSlugsOfSerie = postsWithSerie
    .filter((item) => item.serie.title === serie?.title)
    .map((item) => item.slug);

  const positionPostInSerie = postsWithSerie
    .filter((item) => item.serie.title === serie?.title)
    .map((item) => item.title)
    .indexOf(title);

  const relatedSorted = related
    ? related
        .sort(
          (a, b) =>
            parseInt(b.publishedAt.split("-")[0]) -
            parseInt(a.publishedAt.split("-")[0])
        )
        .sort(
          (a, b) =>
            parseInt(b.publishedAt.split("-")[1]) -
            parseInt(a.publishedAt.split("-")[1])
        )
        .sort(
          (a, b) =>
            parseInt(b.publishedAt.split("-")[2]) -
            parseInt(a.publishedAt.split("-")[2])
        )
    : null;
  // related Posts that are not drafts or posts of the same serie
  const relatedPosts = related
    ? relatedSorted
        .filter((item) => item.draft !== true)
        .filter((item) => (serie ? item.serie?.title !== serie?.title : true))
    : null;

  const [number, setNumber] = useState(3);
  const numberOfTags = tags.length;
  const visibleTags = tags.slice(0, number);

  const [visibility, setVisibility] = useState(tags.length > 3);

  function handleClick() {
    setNumber(numberOfTags);
    setVisibility(!visibility);
  }

  return (
    <>
      <div className="bg-yellow-100 dark:bg-gray-700 md:bg-white md:dark:bg-gray-800 rounded-lg shadow-sm p-3 dark:text-white">
        <div
          className={`${
            (related &&
              related.filter((item) => item.draft !== true).length > 0) ||
            (serie && allPostsOfSerie.length > 1)
              ? "mb-6"
              : "mb-0"
          } md:mb-0 ${
            (related &&
              related.filter((item) => item.draft !== true).length > 0) ||
            (serie && allPostsOfSerie.length > 1)
              ? "fixedHeight"
              : null
          }`}
        >
          <div className="text-xl mb-5">Infos:</div>
          <div className="pb-2 flex flex-wrap">
            <div className="mr-1">Autor: </div>
            <Link to={`/beitraege`} state={{ author: author }}>
              <div className="text-blue-300 font-light">{author}</div>
            </Link>
          </div>
          <div className="pb-2 flex flex-wrap">
            <div className="mr-1">Kategorie:</div>
            <Link to={`/beitraege`} state={{ category: category }}>
              <div className="text-blue-300 font-light">{category}</div>
            </Link>
          </div>

          {serie && (
            <div className="pb-2 flex flex-wrap">
              <div className="mr-1">Reihe:</div>
              <Link to={`/beitraege`} state={{ serie: serie.title }}>
                <div className="text-blue-300 font-light text-left">
                  {serie.title}
                </div>
              </Link>
            </div>
          )}
          {/* >>>TAGS */}
          <div className="flex flex-wrap">
            <div className="mr-1">Tags:</div>
            {visibleTags.map((item, index) => (
              <Link to={`/beitraege`} state={{ keyword: item }} key={index}>
                <div className="mr-2 text-blue-300 font-light">{item}</div>
              </Link>
            ))}
            {visibility && (
              <button
                onClick={() => handleClick()}
                className="text-blue-300 font-light underline"
              >
                mehr
              </button>
            )}
          </div>
          {/* <<<TAGS */}

          {/* >>>PREVIEW */}
          <div className="pt-2 flex items-center">
            <div className="w-12">{(vPreview || tPreview) && "Links: "}</div>
            <div className="flex">
              {vPreview && (
                <a
                  href={vPreview.description}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="group">
                    <FaYoutube size={20} className="mr-2" />
                    <div className="hidden md:group-hover:contents">
                      <div className="absolute z-50 -ml-96">
                        <GatsbyImage
                          image={vPreview.gatsbyImageData}
                          alt={vPreview.title}
                        />
                      </div>
                    </div>
                  </div>
                </a>
              )}
              {tPreview && (
                <a
                  href={tPreview.description}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="group">
                    <FaNewspaper size={20} />
                    <div className="hidden md:group-hover:contents">
                      <div className="absolute z-50 -ml-96">
                        <GatsbyImage
                          image={tPreview.gatsbyImageData}
                          alt={tPreview.title}
                        />
                      </div>
                    </div>
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
        {/* <<<PREVIEW */}
        {/* >>>RELATED */}
        {((related &&
          related.filter((item) => item.draft !== true).length > 0) ||
          (serie && allPostsOfSerie.length > 1)) && (
          <div className="text-xl mb-5 mt-3">Verwandte Beiträge:</div>
        )}
        <div>
          {serie && allPostsOfSerie.length > 1 && (
            <div
              className={
                allPostsOfSerie.length > 2 ||
                (related && relatedPosts.length !== 0)
                  ? "border-b border-gray-300 mb-8 pb-6"
                  : null
              }
            >
              {allPostsOfSerie.length > 1 &&
              allPostsOfSerie.length - 1 > positionPostInSerie ? (
                <div>
                  <Link to={`../${allSlugsOfSerie[positionPostInSerie + 1]}`}>
                    <GatsbyImage
                      image={
                        allImagesOfSerie[positionPostInSerie + 1]?.gallery[0]
                          .gatsbyImageData
                      }
                      alt={allSlugsOfSerie[positionPostInSerie + 1]}
                    />
                    <div className="text-xs text-gray-500">
                      {gerFormatShort(allDatesOfSerie[positionPostInSerie + 1])}
                    </div>
                    <div className="font-bold text-sm py-1">
                      {allPostsOfSerie[positionPostInSerie + 1]}
                    </div>
                    <div className="text-sm">
                      {serie?.title} {serie && `(${positionPostInSerie + 2})`}
                    </div>
                  </Link>
                </div>
              ) : (
                <div>
                  <Link to={`../${allSlugsOfSerie[0]}`}>
                    <GatsbyImage
                      image={allImagesOfSerie[0]?.gallery[0].gatsbyImageData}
                      alt={allSlugsOfSerie[0]}
                    />
                    <div className="text-xs text-gray-500">
                      {gerFormatShort(allDatesOfSerie[0])}
                    </div>
                    <div className="font-bold text-sm py-1">
                      {allPostsOfSerie[0]}
                    </div>
                    <div className="text-sm">
                      {serie?.title} {serie && "(1)"}
                    </div>
                  </Link>
                </div>
              )}
            </div>
          )}
          {allPostsOfSerie.length > 2 && (
            <div>
              {positionPostInSerie !== 0 ? (
                <div
                  className={
                    related && relatedPosts.length !== 0
                      ? "border-gray-300 mb-8 pb-6 border-b"
                      : null
                  }
                >
                  {" "}
                  <Link to={`../${allSlugsOfSerie[positionPostInSerie - 1]}`}>
                    <GatsbyImage
                      image={
                        allImagesOfSerie[positionPostInSerie - 1].gallery[0]
                          .gatsbyImageData
                      }
                      alt={allSlugsOfSerie[positionPostInSerie - 1]}
                    />
                    <div className="text-xs text-gray-500">
                      {gerFormatShort(allDatesOfSerie[positionPostInSerie - 1])}
                    </div>
                    <div className="font-bold text-sm py-1">
                      {allPostsOfSerie[positionPostInSerie - 1]}
                    </div>
                    <div className="text-sm">
                      {serie.title} ({positionPostInSerie})
                    </div>
                  </Link>
                </div>
              ) : (
                <div
                  className={
                    related && relatedPosts.length !== 0
                      ? "border-gray-300 mb-8 pb-6 border-b"
                      : null
                  }
                >
                  <Link
                    to={`../${allSlugsOfSerie[allPostsOfSerie.length - 1]}`}
                  >
                    <GatsbyImage
                      image={
                        allImagesOfSerie[allPostsOfSerie.length - 1].gallery[0]
                          .gatsbyImageData
                      }
                      alt={allSlugsOfSerie[allPostsOfSerie.length - 1]}
                    />
                    <div className="text-xs text-gray-500">
                      {gerFormatShort(
                        allDatesOfSerie[allPostsOfSerie.length - 1]
                      )}
                    </div>
                    <div className="font-bold text-sm py-1">
                      {allPostsOfSerie[allPostsOfSerie.length - 1]}
                    </div>{" "}
                    <div className="text-sm">
                      {serie.title} ({allPostsOfSerie.length})
                    </div>
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
        {related &&
          relatedPosts.map((card, index) => (
            <div
              key={index}
              className="border-gray-300 mb-8 pb-6 border-b last:border-0 last:pb-0 last:mb-0"
            >
              <Link to={`../${card.slug.toLowerCase()}`}>
                <GatsbyImage
                  image={card.gallery[0].gatsbyImageData}
                  alt={card.title}
                />

                <div className="text-xs text-gray-500">
                  {gerFormatShort(card.publishedAt)}
                </div>
                <div className="font-bold text-sm py-1">{card.title}</div>
                <div className="text-sm">
                  {card.serie ? card.serie.title : card.summary}
                  {postsWithSerie
                    .filter((a) =>
                      card.serie ? a.serie.title === card.serie.title : false
                    )
                    .map((item) => item.title)
                    .indexOf(card.title) !== -1 &&
                    ` (${
                      postsWithSerie
                        .filter((a) =>
                          card.serie
                            ? a.serie.title === card.serie.title
                            : false
                        )
                        .map((item) => item.title)
                        .indexOf(card.title) + 1
                    })`}
                </div>
              </Link>
            </div>
          ))}
      </div>
    </>
  );
};

export default PostSidebar;
