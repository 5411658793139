import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export const Text = ({ text }) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <span className="font-semibold">{text}</span>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="text-5xl py-8 mt-6 font-medium">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h1 className="text-2xl font-semibold my-3">{children}</h1>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h1 className="text-xl font-semibold my-3">{children}</h1>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h1 className="text-lg font-semibold my-3">{children}</h1>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h1 className="text-base font-semibold my-3">{children}</h1>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <h1 className="text-sm mb-3 font-medium">{children}</h1>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="my-3">{children}</p>
      ),
      [BLOCKS.UL_LIST]: (node, children) => children,
      [BLOCKS.LIST_ITEM]: (node) => (
        <li className="ml-8">{node.content[0].content[0].value}</li>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <div className="flex py-3">
          <div className="bg-blue-200 w-0.5" />
          <p className="text-sm italic px-6">{children}</p>
        </div>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <div className="pt-6 px-6 text-center">
            <GatsbyImage image={node.data.target.gatsbyImageData} alt="bild" />
          </div>
        );
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <>
          {node.data.uri.includes("martinvu.de") ? (
            <Link to={`../../${node.data.uri.split("/")[3]}`}>
              <span className="link">{children}</span>
            </Link>
          ) : (
            <a
              className="link"
              href={node.data.uri}
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </a>
          )}
        </>
      ),
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };
  const output = renderRichText(text, options);

  return <div className="text-black dark:text-white">{output}</div>;
};

export default Text;
