import React, { useEffect, useState } from "react";
import { FaHeart } from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react";

export const Like = ({ id, Connect }) => {
  const { user } = useAuth0();
  const [alreadyLiked, setAlreadyLiked] = useState(false);
  const [number, setNumber] = useState(null);
  async function ShowNumber(env, postID) {
    let post = await env.getEntry(postID);
    setNumber(post.fields.likes["de"][0].counter);
    post.fields.likes?.["de"].some((d) => d.userId === user.sub)
      ? setAlreadyLiked(true)
      : setAlreadyLiked(false);
  }
  useEffect(() => {
    (async () => {
      let env = await Connect();
      await ShowNumber(env, id);
    })();
  }, []);

  const handleClick = () => {
    async function UpdateNumber(env, postID) {
      if (user["http://localhost:8000/"]?.secondAcc === true) {
        alert("Mit deinem Zweitaccount kannst du nicht liken!");
      } else {
        setAlreadyLiked(!alreadyLiked);
        let post = await env.getEntry(postID);
        if (post.fields.likes["de"].some((d) => d.userId === user.sub)) {
          setNumber(number - 1);
          post.fields.likes["de"][0].counter =
            post.fields.likes["de"][0].counter - 1;
          post.fields.likes["de"] = post.fields.likes["de"].filter(
            (a) => a.userId !== user.sub
          );
          await setNumber(post.fields.likes["de"][0].counter);
          try {
            await post.update();
            post = await env.getEntry(postID);
            await post.publish();
          } catch (err) {
            alert("Upsi.. Möglicherweise ist ein Fehler aufgetreten.");
          }
        } else {
          setNumber(number + 1);
          post.fields.likes["de"][0].counter =
            post.fields.likes["de"][0].counter + 1;
          post.fields.likes["de"].push({
            userId: user.sub,
          });
          await setNumber(post.fields.likes["de"][0].counter);
          try {
            await post.update();
            post = await env.getEntry(postID);
            await post.publish();
          } catch (err) {
            alert("Upsi.. Möglicherweise ist ein Fehler aufgetreten.");
          }
        }
      }
    }

    (async () => {
      let env = await Connect();
      await UpdateNumber(env, id);
    })();
  };

  return (
    <div>
      <div className="">
        <button onClick={handleClick}>
          <div className="right-0 bottom-0 absolute relative transform -translate-x-6">
            <FaHeart className={alreadyLiked ? "text-red-500" : "opacity-30"} />
            <div className="opacity-30 absolute right-0 transform translate-x-6 top-1/2 -translate-y-1/2">
              <div className="w-5 text-center">{number !== null && number}</div>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};
