import React, { useEffect, useState, useRef } from "react";
import Layout from "../layouts/Layout";
import { graphql } from "gatsby";
import SiteMetadata from "../components/SiteMetaData";
import PostSidebar from "../components/PostSidebar";
import { checkRole, secondAcc, userName } from "../components/Utils";
import "regenerator-runtime/runtime";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import { CommentBox } from "../components/CommentBox";
import { PostBody } from "../components/PostBody";
import { Correction } from "../components/Correction";
export default withAuthenticationRequired(Post);

function Post(props) {
  const {
    title,
    originalTitle,
    gallery,
    related,
    richText,
    publishedAt,
    vPreview,
    tPreview,
    category,
    tags,
    draft,
    author,
    summary,
    serie,
    originalLanguage,
    originalText,
    contentful_id,
  } = props.data.item;

  const filteredTags = tags.filter((a) => a.length > 2);

  var today = new Date();
  var date =
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate()) +
    "." +
    (today.getMonth() < 9
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "." +
    today.getFullYear();
  var time =
    (today.getHours() < 10 ? "0" + today.getHours() : today.getHours()) +
    ":" +
    (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes());
  var dateTime = date + " - " + time;

  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef();

  const { user } = useAuth0();

  const [german, setGerman] = useState(true);
  const [correction, setCorrection] = useState(false);
  const [editingMode, setEditingMode] = useState(false);
  const [correctionSpinner, setCorrectionSpinner] = useState(false);
  const [commentSpinner, setCommentSpinner] = useState(false);
  const [text, setText] = useState("");
  const [listReadCorrection, setListReadCorrection] = useState(user.sub);
  const [comments, setComments] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [normalMode, setNormalMode] = useState(true);
  const [replyMode, setReplyMode] = useState(false);
  const [editNumber, setEditNumber] = useState(null);
  const [editReplyNumber, setEditReplyNumber] = useState(null);
  const [commentCounter, setCommentCounter] = useState("a");

  let handleInputChange = (e) => {
    let inputValue = e.target.value;
    setValue(inputValue);
  };

  let [valueComment, setValueComment] = useState(null);

  let handleInputChangeComment = (e) => {
    let inputValueComment = e.target.value;
    setValueComment(inputValueComment);
  };

  const contentful = require("contentful-management");
  async function Connect() {
    let client = contentful.createClient({
      accessToken: "CFPAT-jLjpO9lQUXtJ4wgWWXTuwdayKLQMXWZ0s7aUsX9km3U",
    });
    let space = await client.getSpace("j3cnxbdaejpl");
    return await space.getEnvironment("master");
  }
  async function Updates(env, postID) {
    let post = await env.getEntry(postID);
    setText(post.fields.correction?.["de"][0].correctionText);
    setListReadCorrection(post.fields.correction?.["de"]);
    //Comments-Counter
    if (!draft) {
      setComments(post.fields.comments["de"]);
      setCommentCounter(
        post.fields.comments["de"].length +
          post.fields.comments["de"]
            .map((item) => item.replies?.length)
            .filter((item) => item > 0)
            .reduce((a, b) => a + b, 0)
      );
    }
    if (value === null) {
      setValue(post.fields.correction?.["de"][0].correctionText);
    }
    if (
      !post.fields.comments["de"][0].read.some((d) => d.userId === user.sub)
    ) {
      post.fields.comments["de"][0].read.push({ userId: user.sub });
      await post.update();
      post = await env.getEntry(postID);
      await post.publish();
    }
    if (
      !secondAcc &&
      !post.fields.read["de"].some((d) => d.userId === user.sub)
    ) {
      post.fields.read["de"].push({
        userId: user.sub,
      });
      //Read-Counter
      post.fields.read["de"][0].counter = post.fields.read["de"][0].counter + 1;
      setNumber(post.fields.read["de"][0].counter);
      await post.update();
      post = await env.getEntry(postID);
      await post.publish();
    } else {
      setNumber(post.fields.read["de"][0].counter);
    }
  }
  useEffect(() => {
    (async () => {
      let env = await Connect();
      await Updates(env, props.data.item.contentful_id);
    })();
  }, []);
  let [value, setValue] = useState(null);
  const [number, setNumber] = useState(null);

  const handleClick = () => {
    async function UpdateCorrection(env, postID) {
      setCorrectionSpinner(true);
      setEditingMode(false);
      let post = await env.getEntry(postID);

      setCorrectionSpinner(false);
      post.fields.correction["de"] = await [];
      post.fields.correction["de"][0] = await {
        correctionText: value,
      };
      setText(post.fields.correction["de"][0].correctionText);
      post.fields.correction["de"].push({
        userId: user.sub,
      });
      try {
        await post.update();
        post = await env.getEntry(postID);
        await post.publish();
      } catch (err) {
        alert("Upsi.. Möglicherweise ist ein Fehler aufgetreten.");
      }
    }
    (async () => {
      let env = await Connect();
      await UpdateCorrection(env, props.data.item.contentful_id);
    })();
  };
  const handleSaveComment = () => {
    async function SaveComment(env, postID) {
      setCommentSpinner(true);
      let post = await env.getEntry(postID);
      post.fields.comments["de"].push({
        userId: user.sub,
        name: userName(user),
        comment: valueComment,
        date: dateTime,
      });
      post.fields.comments["de"][0].read = await [];
      post.fields.comments["de"][0].read.push({
        userId: user.sub,
      });
      setCommentCounter(commentCounter + 1);
      await setComments(post.fields.comments["de"]);
      setCommentSpinner(false);
      try {
        await post.update();
        post = await env.getEntry(postID);
        await post.publish();
        document.getElementById("output").value = "";
      } catch (err) {
        alert("Ups, da ist etwas schief gelaufen!");
        let post = await env.getEntry(postID);
        setComments(post.fields.comments["de"]);
      }
    }
    (async () => {
      let env = await Connect();
      await SaveComment(env, props.data.item.contentful_id);
    })();
  };

  const handleDelete = (index, subIndex) => {
    async function DeleteComment(env, postID) {
      setIsOpen(false);
      document.getElementById("output").value = "";
      setEditMode(false);
      setReplyMode(false);
      setNormalMode(true);
      setCommentSpinner(true);
      let post = await env.getEntry(postID);
      if (subIndex >= 0) {
        post.fields.comments["de"][index + 1].replies.splice(subIndex, 1);
      } else {
        post.fields.comments["de"].splice(index + 1, 1);
      }
      setCommentCounter(
        post.fields.comments["de"].length +
          post.fields.comments["de"]
            .map((item) => item.replies?.length)
            .filter((item) => item > 0)
            .reduce((a, b) => a + b, 0)
      );
      await setComments(post.fields.comments["de"]);
      setCommentSpinner(false);
      try {
        await post.update();
        post = await env.getEntry(postID);
        await post.publish();
      } catch (err) {
        alert("Upsi.. Möglicherweise ist ein Fehler aufgetreten.");
        let post = await env.getEntry(postID);
        setComments(post.fields.comments["de"]);
      }
    }
    (async () => {
      let env = await Connect();
      await DeleteComment(env, props.data.item.contentful_id);
    })();
  };

  const handleReplyButton = (index) => {
    setEditMode(false);
    setReplyMode(!replyMode);
    setNormalMode(!normalMode);
    setEditNumber(index);
    document.getElementById("output").scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleEditButton = (index, comment, subIndex) => {
    setEditMode(!editMode);
    setNormalMode(!normalMode);
    setReplyMode(false);
    setEditNumber(index);
    setEditReplyNumber(subIndex);
    document.getElementById("output").value = comment;
    document.getElementById("output").scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleEdit = () => {
    async function EditComment(env, postID) {
      setCommentSpinner(true);
      document.getElementById("output").value = "";
      setEditMode(false);
      setNormalMode(true);
      let post = await env.getEntry(postID);
      if (editReplyNumber >= 0) {
        post.fields.comments["de"][editNumber + 1].replies[editReplyNumber][
          "comment"
        ] = valueComment;
      } else {
        post.fields.comments["de"][editNumber + 1]["comment"] = valueComment;
      }
      await setComments(post.fields.comments["de"]);
      setCommentSpinner(false);
      try {
        await post.update();
        post = await env.getEntry(postID);
        await post.publish();
      } catch (err) {
        alert("Upsi.. Möglicherweise ist ein Fehler aufgetreten.");
        let post = await env.getEntry(postID);
        setComments(post.fields.comments["de"]);
      }
    }
    (async () => {
      let env = await Connect();
      await EditComment(env, props.data.item.contentful_id);
    })();
  };

  const handleReply = () => {
    async function Reply(env, postID) {
      setCommentSpinner(true);
      document.getElementById("output").value = "";
      setReplyMode(false);
      setNormalMode(true);
      let post = await env.getEntry(postID);
      if (post.fields.comments["de"][editNumber + 1].replies) {
        post.fields.comments["de"][editNumber + 1].replies.push({
          userId: user.sub,
          name: userName(user),
          comment: valueComment,
          date: dateTime,
        });
      } else {
        post.fields.comments["de"][editNumber + 1].replies = [
          {
            userId: user.sub,
            name: userName(user),
            comment: valueComment,
            date: dateTime,
          },
        ];
      }
      post.fields.comments["de"][0].read = await [];
      post.fields.comments["de"][0].read.push({
        userId: user.sub,
      });
      setCommentCounter(commentCounter + 1);
      await setComments(post.fields.comments["de"]);
      setCommentSpinner(false);
      try {
        await post.update();
        post = await env.getEntry(postID);
        await post.publish();
      } catch (err) {
        alert("Upsi.. Möglicherweise ist ein Fehler aufgetreten.");
        let post = await env.getEntry(postID);
        setComments(post.fields.comments["de"]);
      }
    }
    (async () => {
      let env = await Connect();
      await Reply(env, props.data.item.contentful_id);
    })();
  };

  const handleClickCorrectionButton = () => {
    setCorrection(!correction);
    async function UpdateUserReadCorrection(env, postID) {
      let post = await env.getEntry(postID);
      if (!post.fields.correction["de"].some((d) => d.userId === user.sub)) {
        if (
          user.sub ===
          ("google-oauth2|101175462350137417985" ||
            "auth0|60bde5e4d2fc940071e20178" ||
            "auth0|60bde5f58bc73d0070042a6d")
        ) {
          post.fields.correction["de"].push(
            { userId: "google-oauth2|101175462350137417985" },
            { userId: "auth0|60bde5e4d2fc940071e20178" },
            { userId: "auth0|60bde5f58bc73d0070042a6d" }
          );
        } else {
          post.fields.correction["de"].push({
            userId: user.sub,
          });
        }
        await post.update();
        post = await env.getEntry(postID);
        await post.publish();
      }
    }
    (async () => {
      let env = await Connect();
      await UpdateUserReadCorrection(env, props.data.item.contentful_id);
    })();
  };

  const Title = () => {
    return (
      <h1 className="text-center text-4xl py-6 lg:hidden">
        {german ? title : originalTitle}
      </h1>
    );
  };

  const Unauthorized = () => {
    return (
      !checkRole(user, "admin") &&
      draft && (
        <div className="bg-gray-50 dark:bg-gray-900 flex-grow dark:text-white">
          <div className="container">
            <div>Du hast für diese Seite keine Berechtigung.</div>
          </div>
        </div>
      )
    );
  };

  return (
    <Layout>
      <SiteMetadata
        title={title}
        description={
          author === "Martin"
            ? `Ein Beitrag von ${author}`
            : `Ein Gastbeitrag von ${author}`
        }
      />
      {((checkRole(user) && draft !== true) ||
        (draft && checkRole(user, "admin"))) && (
        <div className="bg-gray-50 dark:bg-gray-900 flex-grow dark:text-white">
          <div className="container">
            <Title />
            <div className="md:grid grid-cols-4 gap-3">
              <div className="col-span-3">
                <PostBody
                  Connect={Connect}
                  setGerman={setGerman}
                  german={german}
                  richText={richText}
                  originalLanguage={originalLanguage}
                  originalText={originalText}
                  originalTitle={originalTitle}
                  commentCounter={commentCounter}
                  author={author}
                  number={number}
                  title={title}
                  publishedAt={publishedAt}
                  gallery={gallery}
                  contentful_id={contentful_id}
                  draft={draft}
                />
                <Correction
                  editingMode={editingMode}
                  value={value}
                  text={text}
                  setEditingMode={setEditingMode}
                  correctionSpinner={correctionSpinner}
                  handleInputChange={handleInputChange}
                  handleClick={handleClick}
                  listReadCorrection={listReadCorrection}
                  checkRole={checkRole}
                  handleClickCorrectionButton={handleClickCorrectionButton}
                  correction={correction}
                  user={user}
                  draft={draft}
                />
                <CommentBox
                  comments={comments}
                  normalMode={normalMode}
                  replyMode={replyMode}
                  editMode={editMode}
                  handleEditButton={handleEditButton}
                  handleDelete={handleDelete}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  cancelRef={cancelRef}
                  onClose={onClose}
                  handleReplyButton={handleReplyButton}
                  handleSaveComment={handleSaveComment}
                  handleEdit={handleEdit}
                  handleReply={handleReply}
                  commentSpinner={commentSpinner}
                  handleInputChangeComment={handleInputChangeComment}
                  user={user}
                  draft={draft}
                />
              </div>
              <div className="md:flex-1">
                <PostSidebar
                  title={title}
                  summary={summary}
                  serie={serie}
                  category={category.title}
                  author={author}
                  tags={filteredTags}
                  vPreview={vPreview}
                  tPreview={tPreview}
                  related={related}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Unauthorized />
    </Layout>
  );
}

export const query = graphql`
  query BlogItemQuery($slug: String!) {
    item: contentfulPost(slug: { eq: $slug }) {
      title
      contentful_id
      author
      draft
      serie {
        title
      }
      originalLanguage {
        title
        flag {
          gatsbyImageData(
            layout: FIXED
            placeholder: BLURRED
            quality: 100
            width: 25
          )
        }
      }
      category {
        title
      }
      publishedAt(formatString: "DD-MM-YYYY")
      summary
      vPreview {
        title
        description
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          quality: 50
          width: 800
        )
      }
      tPreview {
        title
        description
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          quality: 50
          width: 800
        )
      }
      tags
      richText {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
          }
        }
      }
      originalText {
        raw
        references {
          ... on ContentfulAsset {
            title
            contentful_id
            __typename
            gatsbyImageData
          }
        }
      }
      originalTitle
      gallery {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          quality: 100
          width: 1000
          aspectRatio: 1.77
        )
      }
      related {
        serie {
          title
        }
        gallery {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 100
          )
        }
        title
        publishedAt(formatString: "DD-MM-YYYY")
        summary
        slug
        draft
      }
    }
  }
`;
