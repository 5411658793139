import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";
import Swiper from "react-id-swiper";

import "swiper/css/swiper.css";
import "../styles/HeroImages.css";

export const HeroImages = ({ images }) => {
  const swiperParams = {
    pagination:
      images.length < 2
        ? false
        : {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          },
  };
  return (
    <Swiper {...swiperParams}>
      {images.map((image) => {
        return (
          <div key={`slide_${image.id}`}>
            <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
          </div>
        );
      })}
    </Swiper>
  );
};

HeroImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default HeroImages;
