import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { FaEdit, FaTrashAlt, FaReply } from "react-icons/fa";
import { Textarea } from "@chakra-ui/react";
import { MySpinner } from "../components/Utils";

export const CommentBox = ({
  user,
  comments,
  handleEditButton,
  handleDelete,
  handleReplyButton,
  editMode,
  onClose,
  cancelRef,
  replyMode,
  setIsOpen,
  isOpen,
  handleSaveComment,
  handleReply,
  commentSpinner,
  normalMode,
  handleEdit,
  handleInputChangeComment,
  draft,
}) => {
  return (
    !draft && (
      <div className="bg-white rounded-lg p-3 mb-4 dark:bg-gray-800">
        <div className="text-center text-lg mt-3">Kommentare</div>
        <div className="mt-6">
          {comments?.slice(1, comments.length).map((item, index) => (
            <div
              className="border-b pb-6 mb-8 last:border-0 last:pb-0 last:mb-0"
              key={index}
            >
              <div className="flex justify-between items-center">
                <div className="text-xs opacity-50">{item.date}</div>
                <div className="flex">
                  {user.sub === item.userId && (
                    <div className="flex">
                      {!replyMode && (
                        <button
                          onClick={() => handleEditButton(index, item.comment)}
                        >
                          <FaEdit size={12} className="opacity-30" />
                        </button>
                      )}
                      <div className="ml-2">
                        <>
                          <button onClick={() => setIsOpen(true)}>
                            <FaTrashAlt size={12} className="opacity-30" />
                          </button>
                          <AlertDialog
                            isOpen={isOpen}
                            leastDestructiveRef={cancelRef}
                            onClose={onClose}
                            preserveScrollBarGap
                          >
                            <AlertDialogOverlay>
                              <AlertDialogContent>
                                <AlertDialogHeader
                                  fontSize="lg"
                                  fontWeight="bold"
                                >
                                  Kommentar löschen
                                </AlertDialogHeader>

                                <AlertDialogBody>
                                  Willst du wirklich diesen Kommentar löschen?
                                </AlertDialogBody>

                                <AlertDialogFooter>
                                  <Button ref={cancelRef} onClick={onClose}>
                                    Abbrechen
                                  </Button>
                                  <Button
                                    colorScheme="red"
                                    onClick={() => handleDelete(index)}
                                    ml={3}
                                  >
                                    Löschen
                                  </Button>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialogOverlay>
                          </AlertDialog>
                        </>
                      </div>
                    </div>
                  )}
                  {!editMode && (
                    <div className="ml-8">
                      <button onClick={() => handleReplyButton(index)}>
                        <FaReply size={12} className="opacity-30" />
                      </button>
                    </div>
                  )}
                  {editMode && (
                    <div className="ml-8 invisible">
                      <FaReply size={12} />
                    </div>
                  )}
                </div>
              </div>
              <div className="font-bold">{item.name}</div>
              <div className="whitespace-pre-wrap">{item.comment}</div>
              {item.replies?.map((item, subIndex) => (
                <div className="ml-6 mt-6" key={subIndex}>
                  <div className="flex justify-between items-center h-[24px]">
                    <div className="text-xs opacity-50">{item.date}</div>
                    {user.sub === item.userId && (
                      <div className="flex items-center">
                        <button
                          onClick={() =>
                            handleEditButton(index, item.comment, subIndex)
                          }
                        >
                          <FaEdit size={12} className="opacity-30" />
                        </button>
                        <div className="ml-2">
                          <button onClick={() => handleDelete(index, subIndex)}>
                            <FaTrashAlt size={12} className="opacity-30" />
                          </button>
                        </div>
                        <div className="ml-8 invisible">
                          <FaReply size={12} />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="font-bold">{item.name}</div>
                  <div className="whitespace-pre-wrap">{item.comment}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="mt-10">
          <div className="relative">
            <Textarea
              id="output"
              rows={3}
              onChange={handleInputChangeComment}
              placeholder="Dein Kommentar"
            />
            {commentSpinner && <MySpinner />}
          </div>

          {normalMode && (
            <button className="submitButton" onClick={handleSaveComment}>
              Kommentieren
            </button>
          )}
          {editMode && (
            <button
              className="bg-green-500 px-4 py-2 font-bold rounded text-white"
              onClick={handleEdit}
            >
              Ersetzen
            </button>
          )}
          {replyMode && (
            <button
              className="bg-green-500 px-4 py-2 font-bold rounded text-white"
              onClick={handleReply}
            >
              Antworten
            </button>
          )}
        </div>
      </div>
    )
  );
};
